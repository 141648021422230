import { useState, useEffect, useContext } from 'react';
import FirebaseContext from '../../context/FirebaseContext';
import {
  getFirestore,
  collection,
  where,
  orderBy,
  query,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';

import { toast } from 'react-toastify';
import ScheduleHeader from '../../components/ScheduleHeader/ScheduleHeader';
import './PendingEvents.css';
import EventCard from '../../components/EventCard/EventCard';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import { emailChangeStatus } from '../../utils/emailController';

function AllEvents() {
  const { setLoading, isAdmin, user } = useContext(FirebaseContext);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const getUserInfo = async (userRef) => {
      try {
        const db = getFirestore();
        const collectionRef = collection(db, 'users');
        const q = query(collectionRef, where('userRef', '==', userRef));
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        return data[0];
      } catch (error) {
        toast.error(error.message);
      }
    };

    const getAllEvents = async () => {
      setLoading(true);
      const db = getFirestore();
      if (isAdmin) {
        const collectionRef = collection(db, 'events');
        const q1 = where('status', '==', 'pending');
        const order = orderBy('createdAt', 'desc');
        const queryRef = query(collectionRef, q1, order);
        const querySnap = await getDocs(queryRef);
        const data = [];
        for (const event of querySnap.docs) {
          const userInfo = await getUserInfo(event.data().userRef);
          data.push({ ...event.data(), userInfo, id: event.id });
        }
        setEvents(data);
      }

      setLoading(false);
    };

    getAllEvents();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeStatus = async (id, status) => {
    setLoading(true);
    try {
      const db = getFirestore();
      const docRef = doc(db, 'events', id);
      await updateDoc(docRef, { status: status });
      const newEvents = events.map((event) => {
        if (event.id === id) {
          event.status = status;
        }
        return event;
      });
      setEvents(newEvents);

      // Send email to user
      const currentEvent = events.find((event) => event.id === id);
      await emailChangeStatus(currentEvent, user);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const db = getFirestore();
      const docRef = doc(db, 'events', id);
      await deleteDoc(docRef);

      // Send email to user
      const currentEvent = events.find((event) => event.id === id);
      currentEvent.status = 'cancelled';
      await emailChangeStatus(currentEvent, user);

      const newEvents = events.filter((event) => event.id !== id);
      setEvents(newEvents);
      toast.success('Event has been deleted!');
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  return (
    <section className="pending-events-section px-10">
      <ScheduleHeader />
      <h1>
        <PendingActionsOutlinedIcon fontSize="large" />
        Pending Events
      </h1>
      <EventCard
        events={events}
        handleDelete={handleDelete}
        adminControls={true}
        handleChangeStatus={handleChangeStatus}
      />
    </section>
  );
}

export default AllEvents;
