import { useContext, useEffect } from 'react';
import { Navigate } from 'react-router-dom';

import { getAuth } from 'firebase/auth';
import FirebaseContext from '../../context/FirebaseContext';

function Logout() {
  const { loggedIn, setLoading } = useContext(FirebaseContext);

  useEffect(() => {
    if (loggedIn) {
      setLoading(true);
      const auth = getAuth();
      auth.signOut();
      setLoading(false);
    }
  }, []); // eslint-disable-line

  return <Navigate to="/" />;
}

export default Logout;
