import { useState, useEffect, useContext } from 'react';
import { getFirestore, collection, where, query, orderBy, getDocs, doc, updateDoc } from 'firebase/firestore';
import { toast } from 'react-toastify';
import FirebaseContext from '../../context/FirebaseContext';
function Vacations() {
  const { setLoading } = useContext(FirebaseContext);
  const [users, setUsers] = useState([]);

  useEffect(() => {
    const getUsers = async () => {
      setLoading(true);
      try {
        const fetchedUsers = [];
        const db = getFirestore();
        const collectionRef = collection(db, 'users');
        const sort = orderBy('firstName', 'asc');
        const queryRef = query(collectionRef, sort);
        const querySnap = await getDocs(queryRef);

        for (const user of querySnap.docs) {
          const userInfo = { ...user.data(), id: user.id };

          const collectionRef = collection(db, 'events');
          const beginDate = new Date();
          beginDate.setFullYear(new Date().getFullYear(), 0, 1);
          const endDate = new Date();
          endDate.setFullYear(new Date().getFullYear(), 11, 31);
          const q1 = where('userRef', '==', userInfo.userRef);
          const q2 = where('status', '==', 'approved');
          const q3 = where('reason', '==', 'vacation');
          const q4 = where('startDate', '>=', beginDate);
          const queryRef = query(collectionRef, q1, q2, q3, q4);
          const dataSnap = await getDocs(queryRef);
          const events = dataSnap.docs.map((doc) => doc.data());
          const totalVacations = events.reduce((acc, event) => {
            return acc + event.requestedDays;
          }, 0);
          const remainingVacations = userInfo.vacationDays + userInfo.vacationCorrection - totalVacations;
          userInfo.totalVacations = totalVacations;
          userInfo.remainingVacations = remainingVacations;
          fetchedUsers.push(userInfo);
        }

        setUsers(fetchedUsers);
      } catch (error) {
        toast.error(error.message);
      }
      setLoading(false);
    };

    getUsers();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <table className="w-full divide-y divide-gray-200 shadow border-b border-gray-200 rounded-lg overflow-x-scroll">
      <thead className="bg-gray-50 w-full">
        <tr>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Name
          </th>
          <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
            Email
          </th>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            Days / Year
          </th>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            Correction
          </th>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            Remaining
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-gray-200 w-full">
        {users.map((user) => {
          return (
            <tr key={user.id}>
              <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">
                {user.firstName + ' ' + user.lastName}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{user.email}</td>
              <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">
                <input
                  type="number"
                  value={user.vacationDays}
                  className="input input-bordered input-sm w-20"
                  min={0}
                  onChange={async (e) => {
                    const currentUser = users.find((u) => u.id === user.id);
                    currentUser.vacationDays = Number(e.target.value);
                    currentUser.remainingVacations =
                      currentUser.vacationDays + currentUser.vacationCorrection - currentUser.totalVacations;
                    setUsers([...users]);
                    const db = getFirestore();
                    const userRef = doc(db, 'users', user.id);
                    await updateDoc(userRef, { vacationDays: Number(e.target.value) });
                    toast.success('Vacation days updated');
                  }}
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">
                <input
                  type="number"
                  value={user.vacationCorrection}
                  className="input input-bordered input-sm w-20"
                  onChange={async (e) => {
                    const currentUser = users.find((u) => u.id === user.id);
                    currentUser.vacationCorrection = Number(e.target.value);
                    currentUser.remainingVacations =
                      currentUser.vacationDays + currentUser.vacationCorrection - currentUser.totalVacations;
                    setUsers([...users]);
                    const db = getFirestore();
                    const userRef = doc(db, 'users', user.id);
                    await updateDoc(userRef, { vacationCorrection: Number(e.target.value) });
                    toast.success('Vacation correction updated');
                  }}
                />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">
                {user.remainingVacations}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default Vacations;
