import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App.js';
import { FirebaseProvider } from './context/FirebaseContext';
import { ServerProvider } from './context/ServerContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FirebaseProvider>
      <ServerProvider>
        <App />
      </ServerProvider>
    </FirebaseProvider>
  </React.StrictMode>
);
