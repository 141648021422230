import { useState, useEffect } from 'react';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

function Modal({ openModal, setModalOpen, setModalValue }) {
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState('');

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  const handleConfirm = () => {
    setModalValue(value);
    setModalOpen(false);
    setValue('');
    setOpen(false);
  };

  const handleCancel = () => {
    setValue('');
    setModalValue('');
    setModalOpen(false);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Enter your current password</DialogTitle>
      <DialogContent>
        <DialogContentText>To change your information, please enter your current password.</DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Password"
          type="password"
          fullWidth
          variant="standard"
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  );
}

export default Modal;
