import { createContext, useEffect, useRef, useState } from 'react';

const ServerContext = createContext();

const ServerProvider = ({ children }) => {
  const [serverStatus, setServerStatus] = useState('offline');
  const [serverConnected, setServerConnected] = useState(false);
  const isMounted = useRef(true);

  const checkConnection = () => {
    if (isMounted) {
      setServerStatus('connecting');
      fetch(`${process.env.REACT_APP_SERVER_URL}/api/v1/status`)
        .then((res) => res.json())
        .then((data) => {
          if (data.status) {
            setServerStatus('online');
            setServerConnected(true);
          } else {
            setServerStatus('offline');
            setServerConnected(false);
          }
        })
        .catch((err) => {
          setServerStatus('offline');
          setServerConnected(false);
        });
    }
  };

  useEffect(() => {
    checkConnection();
    const interval = setInterval(() => {
      checkConnection();
    }, 1000 * 60 * 5); // 5 minutes

    return () => {
      isMounted.current = false;
      clearInterval(interval);
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <ServerContext.Provider
      value={{
        serverStatus,
        serverConnected,
      }}
    >
      {children}
    </ServerContext.Provider>
  );
};

export default ServerContext;
export { ServerProvider };
