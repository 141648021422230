import { useState, useContext, useEffect } from 'react';
import { toast } from 'react-toastify';
import {
  getAuth,
  updateProfile,
  updateEmail,
  reauthenticateWithCredential,
  EmailAuthProvider,
  updatePassword,
} from 'firebase/auth';
import { getFirestore, doc, collection, where, getDocs, query, updateDoc } from 'firebase/firestore';
import FirebaseContext from '../../context/FirebaseContext';
import Modal from '../../components/Modal/Modal';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { MuiColorInput } from 'mui-color-input';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';

function Profile() {
  const { user, setLoading, setUpdateUser } = useContext(FirebaseContext);

  const [firstName, setFirstName] = useState(user.firstName);
  const [lastName, setLastName] = useState(user.lastName);
  const [email, setEmail] = useState(user.email);
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [color, setColor] = useState(user.color);

  const [changingEmail, setChangingEmail] = useState(false);
  const [changingPassword, setChangingPassword] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalValue, setModalValue] = useState('');

  const handleSubmitName = async () => {
    if (firstName === '' || lastName === '') {
      toast.error('First and last names are required');
      return;
    }
    setLoading(true);
    try {
      // update firebase auth
      const auth = getAuth();
      await updateProfile(auth.currentUser, {
        displayName: `${firstName} ${lastName}`,
      });
      // Update user in firestore
      const db = getFirestore();
      const docRef = doc(db, 'users', user.userRef);
      await updateDoc(docRef, { firstName, lastName });

      setUpdateUser(true);
      toast.success('Name Updated');
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleSubmitEmail = async () => {
    if (email === '') {
      toast.error('Email is required');
      return;
    }

    setChangingEmail(true);
    setModalOpen(true);
  };

  const handleSubmitPassword = async () => {
    if (newPassword === '' || confirmPassword === '') {
      toast.error('Passwords are required');
      return;
    }

    if (newPassword !== confirmPassword) {
      toast.error('Passwords do not match');
      return;
    }

    setChangingPassword(true);
    setModalOpen(true);
  };

  useEffect(() => {
    const changeEmail = async () => {
      const userPassword = modalValue;
      setLoading(true);
      try {
        // reauthenticate user
        const auth = getAuth();
        const credential = EmailAuthProvider.credential(user.email, userPassword);
        await reauthenticateWithCredential(auth.currentUser, credential);

        // update firebase auth
        await updateEmail(auth.currentUser, email);

        // Update user in firestore
        const db = getFirestore();
        const docRef = doc(db, 'users', user.userRef);
        await updateDoc(docRef, { email });

        setUpdateUser(true);
        setModalValue('');
        toast.success('Email Updated');
      } catch (error) {
        toast.error(error.message);
      }

      setChangingEmail(false);
      setLoading(false);
    };

    const changePassword = async () => {
      const userPassword = modalValue;
      setLoading(true);
      try {
        // reauthenticate user
        const auth = getAuth();
        const credential = EmailAuthProvider.credential(user.email, userPassword);
        await reauthenticateWithCredential(auth.currentUser, credential);

        // update firebase auth
        await updatePassword(auth.currentUser, newPassword);

        setUpdateUser(true);
        toast.success('Password Updated');
      } catch (error) {
        toast.error(error.message);
      }
      setChangingPassword(false);
      setModalValue('');
      setLoading(false);
    };

    if (modalValue !== '') {
      changingEmail && changeEmail();
      changingPassword && changePassword();
    }
  }, [modalValue]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmitColor = async () => {
    setLoading(true);
    try {
      // Update user in firestore
      const db = getFirestore();
      const docRef = doc(db, 'users', user.userRef);
      await updateDoc(docRef, { color });

      // Change all events to new color
      const collectionRef = collection(db, 'events');
      const q1 = where('userRef', '==', user.uid);
      const queryRef = query(collectionRef, q1);
      const querySnap = await getDocs(queryRef);
      const data = querySnap.docs.map((doc) => {
        return { ...doc.data(), id: doc.id };
      });

      for (const event of data) {
        const docRef = doc(db, 'events', event.id);
        await updateDoc(docRef, { color });
      }

      setUpdateUser(true);
      toast.success('Color Updated');
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  return (
    <section className="new-user px-5 flex flex-col justify-start w-full gap-[30px]">
      <Modal openModal={modalOpen} setModalOpen={setModalOpen} setModalValue={setModalValue} />
      <h1 className="text-lg font-semibold">User Profile</h1>
      <Box className="flex justify-start items-end mt-5">
        <AccountCircleOutlinedIcon sx={{ color: 'action.active', mr: 2, my: 0.5 }} />
        <TextField
          id="user-first-name"
          type="text"
          label="First Name"
          autoComplete="given-name"
          variant="standard"
          style={{ width: 400 }}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <Button
          variant="contained"
          size="small"
          color="secondary"
          style={{ marginLeft: 20 }}
          className="w-[120px]"
          onClick={handleSubmitName}
        >
          Update
        </Button>
      </Box>
      <Box className="flex justify-start items-end mt-5">
        <AccountCircleOutlinedIcon sx={{ color: 'action.active', mr: 2, my: 0.5 }} />
        <TextField
          id="user-last-name"
          type="text"
          label="Last Name"
          autoComplete="family-name"
          variant="standard"
          style={{ width: 400 }}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <Button
          variant="contained"
          size="small"
          color="secondary"
          style={{ marginLeft: 20 }}
          className="w-[120px]"
          onClick={handleSubmitName}
        >
          Update
        </Button>
      </Box>
      <Box className="flex justify-start items-end mt-5">
        <EmailOutlinedIcon sx={{ color: 'action.active', mr: 2, my: 0.5 }} />
        <TextField
          id="user-email"
          type="email"
          label="Email"
          autoComplete="email"
          variant="standard"
          style={{ width: 400 }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          variant="contained"
          size="small"
          color="secondary"
          style={{ marginLeft: 20 }}
          className="w-[120px]"
          onClick={handleSubmitEmail}
        >
          Update
        </Button>
      </Box>
      <Box className="flex justify-start items-end mt-5">
        <LockOutlinedIcon sx={{ color: 'action.active', mr: 2, my: 0.5 }} />
        <TextField
          id="user-new-password"
          type="password"
          label="New Password"
          autoComplete="new-password"
          variant="standard"
          style={{ width: 400 }}
          value={newPassword}
          onChange={(e) => setNewPassword(e.target.value)}
        />
      </Box>
      <Box className="flex justify-start items-end mt-5">
        <LockOutlinedIcon sx={{ color: 'action.active', mr: 2, my: 0.5 }} />
        <TextField
          id="user-confirm-password"
          type="password"
          label="Confirm Password"
          autoComplete="new-password"
          variant="standard"
          style={{ width: 400 }}
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
        />
        <Button
          variant="contained"
          size="small"
          color="secondary"
          style={{ marginLeft: 20 }}
          className="w-[120px]"
          onClick={handleSubmitPassword}
        >
          Update
        </Button>
      </Box>
      <Box className="flex justify-start items-end mt-7">
        <ColorLensOutlinedIcon sx={{ color: 'action.active', mr: 2, my: 0.5 }} />
        <MuiColorInput
          value={color}
          onChange={(color1) => {
            // RGB string
            const rgb = color1;
            // Convert to hex
            const hex = rgb
              .replace(/^rgb\(|\s+|\)$/g, '')
              .split(',')
              .map((x) => {
                const hex = Number(x).toString(16);
                return hex.length === 1 ? '0' + hex : hex;
              })
              .join('');
            setColor(`#${hex}`);
          }}
          style={{ width: 400 }}
        />
        <Button
          variant="contained"
          size="small"
          color="secondary"
          style={{ marginLeft: 20, alignSelf: 'flex-end' }}
          className="w-[120px]"
          onClick={handleSubmitColor}
        >
          Update
        </Button>
      </Box>
    </section>
  );
}

export default Profile;
