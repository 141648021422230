import { useState, useEffect, useContext } from 'react';
import FirebaseContext from '../../context/FirebaseContext';
import { getFirestore, collection, where, orderBy, query, getDocs, doc, deleteDoc, getDoc } from 'firebase/firestore';

import { toast } from 'react-toastify';
import ScheduleHeader from '../../components/ScheduleHeader/ScheduleHeader';
import './YourEvents.css';
import EventCard from '../../components/EventCard/EventCard';

import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';

function YourEvents() {
  const { user, setLoading } = useContext(FirebaseContext);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const getUserInfo = async (userRef) => {
      try {
        const db = getFirestore();
        const docRef = doc(db, 'users', userRef);
        const dataSnap = await getDoc(docRef);
        // const collectionRef = collection(db, 'users');
        // const q = query(collectionRef, where('userRef', '==', userRef));
        // const querySnapshot = await getDocs(q);
        // const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        const data = dataSnap.data();
        const data2 = { ...data, id: doc.id };
        return data2;
      } catch (error) {
        toast.error(error.message);
      }
    };

    const getAllEvents = async () => {
      setLoading(true);
      const db = getFirestore();
      const collectionRef = collection(db, 'events');
      const q1 = where('userRef', '==', user.uid);
      const order = orderBy('createdAt', 'desc');
      const queryRef = query(collectionRef, q1, order);
      const dataSnap = await getDocs(queryRef);
      const userInfo = await getUserInfo(user.uid);
      const data = dataSnap.docs.map((doc) => {
        return { ...doc.data(), userInfo, id: doc.id };
      });
      setEvents(data);
      setLoading(false);
    };

    getAllEvents();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleDelete = async (id) => {
    try {
      const db = getFirestore();
      const docRef = doc(db, 'events', id);
      await deleteDoc(docRef);
      const newEvents = events.filter((event) => event.id !== id);
      setEvents(newEvents);
      toast.success('Event has been deleted!');
    } catch (error) {
      toast.error(error.message);
    }
  };

  return (
    <section className="your-events-section px-10">
      <ScheduleHeader />
      <h1>
        <LocalActivityOutlinedIcon fontSize="large" />
        Your Events
      </h1>
      <EventCard events={events} handleDelete={handleDelete} />
    </section>
  );
}

export default YourEvents;
