import { useContext } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FirebaseContext from './context/FirebaseContext';

import Body from './components/layout/Body.jsx';
import Navbar from './components/layout/Navbar.jsx';
import AdminPanel from './pages/adminPanel/AdminPanel';
import Editor from './pages/editor/Editor';
import Login from './pages/login/Login';
import Logout from './pages/logout/Logout';
import Report from './pages/report/Report.jsx';
import AddNewEvent from './pages/schedule/AddNewEvent';
import AllEvents from './pages/schedule/AllEvents';
import PendingEvents from './pages/schedule/PendingEvents';
import Schedule from './pages/schedule/Schedule.jsx';
import YourEvents from './pages/schedule/YourEvents';
import Services from './pages/services/Services.jsx';
import Profile from './pages/user/Profile';

function App() {
  const { user, isAdmin } = useContext(FirebaseContext);
  return (
    <Router>
      <main className="m-auto w-full min-h-screen">
        <Navbar />
        <Body>
          {!user ? (
            <Routes>
              <Route path="/forgot-password" element={<h1>Forgot Password</h1>} />
              <Route path="/*" element={<Login />} />
            </Routes>
          ) : (
            <Routes>
              <Route path="/" element={<Services />} />
              {/* <Route path="/login" element={<Login />} /> */}
              <Route path="/logout" element={<Logout />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/schedule" element={<Schedule />} />
              <Route path="/schedule/add-new-event" element={<AddNewEvent />} />
              <Route path="/schedule/your-events" element={<YourEvents />} />
              <Route path="/schedule/pending-events" element={<PendingEvents />} />
              <Route path="/schedule/all-events" element={<AllEvents />} />
              <Route path="/daily-report" element={<Report />} />
              <Route path="/editor" element={<Editor />} />
              {isAdmin && <Route path="/admin-panel/*" element={<AdminPanel />} />}
              <Route path="/*" element={<Navigate to="/" />} />
            </Routes>
          )}
        </Body>
        <ToastContainer autoClose={2000} />
      </main>
    </Router>
  );
}

export default App;
