import { Link } from 'react-router-dom';
import { useContext } from 'react';
import FirebaseContext from '../../context/FirebaseContext';
import ServerContext from '../../context/ServerContext';
import companyLogo from '../../assets/icons/logo.png';
import Avatar from '@mui/material/Avatar';
import { deepPurple } from '@mui/material/colors';
import { motion } from 'framer-motion';

function Navbar() {
  const { user, loggedIn, isAdmin } = useContext(FirebaseContext);
  const { serverStatus, serverConnected } = useContext(ServerContext);
  return (
    <div className="navbar bg-base-100 shadow-md rounded-b-lg justify-between px-6">
      {/* LOGO */}

      <Link to="/" className="btn btn-ghost normal-case text-xl flex justify-start items-center gap-3">
        <img className="h-8" src={companyLogo} alt="company logo" />
        <h2 className="tablet:hidden">Geotech Services</h2>
      </Link>

      {user && (
        <div className="flex space-x-1">
          <h1 className="tablet:hidden">Server Status: </h1>
          <span className="font-bold">{serverStatus.charAt(0).toUpperCase() + serverStatus.slice(1)}</span>
          {serverConnected ? (
            <span className="tooltip tooltip-bottom cursor-pointer" data-tip="Server Online">
              🟢
            </span>
          ) : serverStatus === 'connecting' ? (
            <motion.span
              initial={{
                opacity: 0,
              }}
              animate={{
                opacity: 1,
              }}
              transition={{
                delay: 0.5,
                duration: 1,
                repeatType: 'reverse',
                repeat: Infinity,
              }}
              className="tooltip tooltip-bottom cursor-pointer"
              data-tip="Server Connecting"
            >
              🟡
            </motion.span>
          ) : (
            <span className="tooltip tooltip-bottom cursor-pointer" data-tip="Server Disconnected">
              🔴
            </span>
          )}
        </div>
      )}
      <div className="flex justify-center items-center gap-3">
        {/* USER NAME */}
        {loggedIn && user ? <h1 className="tablet:hidden">{user.firstName + ' ' + user.lastName}</h1> : null}
        {/* MENU */}
        <div className="dropdown dropdown-end">
          <label tabIndex="0" className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              {loggedIn && user ? (
                <Avatar sx={{ bgcolor: `${user.color}`, fontSize: 16 }}>{user.firstName[0] + user.lastName[0]}</Avatar>
              ) : (
                <Avatar sx={{ bgcolor: deepPurple[500] }}></Avatar>
              )}
            </div>
          </label>
          <ul tabIndex="0" className="menu menu-compact dropdown-content mt-3 p-2 shadow bg-base-100 rounded-box w-48">
            {loggedIn && user ? (
              <>
                {isAdmin && (
                  <li>
                    <Link to="/admin-panel" className="font-bold">
                      Control Panel
                    </Link>
                  </li>
                )}
                <li>
                  <Link to="/profile">Profile</Link>
                </li>
                <li>
                  <Link to="/logout">Logout</Link>
                </li>
              </>
            ) : (
              <li>
                <Link to="/login">Login</Link>
              </li>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
