import Calendar from 'react-awesome-calendar';
import './Calendar.css';

function CalendarComponent({ events }) {
  const modifiedEvents = events.map((event) => {
    const timezoneOffset = Math.abs(Number(new Date().getTimezoneOffset() * 60000));

    const startDate = new Date(event.startDate.seconds * 1000 + timezoneOffset);
    const toDate = new Date(event.endDate.seconds * 1000 + timezoneOffset);
    return {
      id: event.userRef + Math.floor(Math.random() * 1000000000),
      color: event.status === 'pending' ? '#FFA500' : event.color,
      from: startDate,
      to: toDate,
      title:
        event.userData.firstName +
        ' ' +
        event.userData.lastName +
        ' - ' +
        event.requestedDays +
        ' day' +
        (event.requestedDays > 1 ? 's' : ''),
    };
  });

  return <Calendar events={modifiedEvents} />;
}

export default CalendarComponent;
