import { toast } from 'react-toastify';

export const emailChangeStatus = async (currentEvent, user) => {
  fetch(process.env.REACT_APP_SERVER_URL + '/api/v1/vacation/change-status-email', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      sender: user.email,
      receiver: currentEvent.userInfo.email,
      reason: currentEvent.reason,
      status: currentEvent.status,
      firstName: currentEvent.userInfo.firstName,
      startDate: currentEvent.startDate,
      endDate: currentEvent.endDate,
      requestedDays: currentEvent.requestedDays,
      actualDays: currentEvent.actualDays,
    }),
  })
    .then((res) => {
      if (res.status === 200) {
        toast.success('Email sent successfully');
      }
    })
    .catch((error) => {
      toast.error(error.message);
    });
};

export const emailNewUser = async (email, password) => {
  fetch(process.env.REACT_APP_SERVER_URL + '/api/v1/new-user', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      email,
      password,
    }),
  })
    .then((res) => {
      if (res.status === 200) {
        toast.success('Email sent successfully');
      }
    })
    .catch((error) => {
      toast.error(error.message);
    });
};

export const emailNewEvent = async (currentEvent) => {
  fetch(process.env.REACT_APP_SERVER_URL + '/api/v1/vacation/new-event', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      reason: currentEvent.reason,
      firstName: currentEvent.firstName,
      lastName: currentEvent.lastName,
      startDate: currentEvent.startDate,
      endDate: currentEvent.endDate,
      requestedDays: currentEvent.requestedDays,
      actualDays: currentEvent.actualDays,
    }),
  })
    .then((res) => {
      if (res.status === 200) {
        toast.success('Email sent successfully');
      }
    })
    .catch((error) => {
      toast.error(error.message);
    });
};
