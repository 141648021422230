import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';

const firebaseConfig = {
  apiKey: 'AIzaSyA9KaoXfeo89jqBLmpmt9fl6GShiJHq1iE',
  authDomain: 'geotech-services.firebaseapp.com',
  projectId: 'geotech-services',
  storageBucket: 'geotech-services.appspot.com',
  messagingSenderId: '908533687130',
  appId: '1:908533687130:web:688436deccf515b1240963',
};

const app = initializeApp(firebaseConfig);

export const secondaryUser = initializeApp(firebaseConfig, 'SecondaryUser');
export const db = getFirestore(app);
