import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import './Services.css';

import calendarIcon from '../../assets/icons/calendar.png';
import clientsIcon from '../../assets/icons/clients.png';
import editorIcon from '../../assets/icons/editor.png';
import reportIcon from '../../assets/icons/report.png';

function Services() {
  return (
    <section className="services">
      <motion.a
        href="https://clients.geotech-energy.com"
        target="_blank"
        className="container shadow-lg rounded-xl outline outline-2 hover:outline-emerald-500 hover:shadow-xl"
        whileHover={{ scale: 1.05 }}
      >
        <img src={clientsIcon} alt="clients" />
        <h2>Clients</h2>
      </motion.a>
      <motion.div
        className="container shadow-lg rounded-xl outline outline-2 hover:outline-emerald-500 hover:shadow-xl"
        whileHover={{ scale: 1.05 }}
      >
        <Link to="/schedule" className="container">
          <img src={calendarIcon} alt="schedule" />
          <h2>Schedule</h2>
        </Link>
      </motion.div>
      <motion.div
        className="container shadow-lg rounded-xl outline outline-2 hover:outline-emerald-500 hover:shadow-xl ribbon-tag"
        data-ribbon="Coming Soon"
        style={{
          '--ribbon-color': '#f6ad55',
          overflow: 'unset',
        }}
        whileHover={{ scale: 1.05 }}
      >
        <Link to="/daily-report" className="container">
          <img src={reportIcon} alt="daily report" />
          <h2>Daily Report</h2>
        </Link>
      </motion.div>
      <motion.div
        className="container shadow-lg rounded-xl outline outline-2 hover:outline-emerald-500 hover:shadow-xl"
        whileHover={{ scale: 1.05 }}
      >
        <Link to="/editor" className="container">
          <img src={editorIcon} alt="schedule" />
          <h2>Email Editor</h2>
        </Link>
      </motion.div>
    </section>
  );
}

export default Services;
