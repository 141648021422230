import Button from '@mui/material/Button';
import EventBusyOutlinedIcon from '@mui/icons-material/EventBusyOutlined';
import WorkOffOutlinedIcon from '@mui/icons-material/WorkOffOutlined';
import OutboxOutlinedIcon from '@mui/icons-material/OutboxOutlined';
import MoveToInboxOutlinedIcon from '@mui/icons-material/MoveToInboxOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import EventOutlinedIcon from '@mui/icons-material/EventOutlined';
import ForwardToInboxOutlinedIcon from '@mui/icons-material/ForwardToInboxOutlined';
import CoronavirusOutlinedIcon from '@mui/icons-material/CoronavirusOutlined';
import AirportShuttleOutlinedIcon from '@mui/icons-material/AirportShuttleOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import BlockOutlinedIcon from '@mui/icons-material/BlockOutlined';
import WorkHistoryOutlinedIcon from '@mui/icons-material/WorkHistoryOutlined';
import dateFormatter from '../../utils/dateFormatter';

import './EventCard.css';

function EventCard({ events, handleDelete, adminControls, handleChangeStatus }) {
  return (
    <div className="events-container">
      {events.length > 0 ? (
        events.map((event) => (
          <div
            key={event.userRef + event.endDate.seconds + Math.random()}
            data-ribbon={
              event.status === 'approved' ? 'Approved' : event.status === 'rejected' ? 'Rejected' : 'Pending'
            }
            // lightgreen #90ee90
            style={
              event.status === 'approved'
                ? {
                    '--ribbon-color': '#90ee90',
                  }
                : event.status === 'rejected'
                ? {
                    '--ribbon-color': 'red',
                  }
                : {
                    '--ribbon-color': 'orange',
                  }
            }
            className={
              event.status === 'approved'
                ? 'event-card shadow-md rounded-lg border-2 border-success'
                : event.status === 'rejected'
                ? 'event-card shadow-md rounded-lg border-2 border-error'
                : event.status === 'pending' && 'event-card shadow-md rounded-lg border-2 border-warning'
            }
          >
            <h3>
              {event.reason.charAt(0).toUpperCase() + event.reason.slice(1)} for{' '}
              {event.userInfo.firstName + ' ' + event.userInfo.lastName}{' '}
              {event.reason === 'vacation' ? (
                <AirportShuttleOutlinedIcon />
              ) : event.reason === 'sick' ? (
                <CoronavirusOutlinedIcon />
              ) : (
                event.reason === 'dayoff' && <WorkOffOutlinedIcon />
              )}
            </h3>
            <p>
              <OutboxOutlinedIcon />
              From: {dateFormatter(new Date(event.startDate.seconds * 1000))}
            </p>
            <p>
              <MoveToInboxOutlinedIcon />
              To: {dateFormatter(new Date(event.endDate.seconds * 1000))}
            </p>
            <p>
              <EventOutlinedIcon /> Requested Days: {event.requestedDays}
            </p>
            {event.reason === 'dayoff' ? (
              <p>
                <WorkHistoryOutlinedIcon />
                Job Took Place from {dateFormatter(new Date(event.dayoffStartDate.seconds * 1000))} to{' '}
                {dateFormatter(new Date(event.dayoffEndDate.seconds * 1000))}
              </p>
            ) : (
              <p>
                <TodayOutlinedIcon />
                Actual Days: {event.actualDays}
              </p>
            )}
            <p>
              <ForwardToInboxOutlinedIcon /> Submitted: {dateFormatter(new Date(event.createdAt.seconds * 1000))}
            </p>

            {(event.status === 'pending' ||
              (event.status !== 'pending' && new Date() <= event.startDate.seconds * 1000)) &&
              !adminControls && (
                <div className="buttons">
                  <Button
                    variant="contained"
                    startIcon={<BlockOutlinedIcon />}
                    color="secondary"
                    onClick={() => {
                      handleDelete(event.id);
                    }}
                  >
                    Cancel Event
                  </Button>
                </div>
              )}

            {adminControls && (
              <div className="buttons flex justify-start items-center gap-5 w-full flex-wrap mobile:justify-center">
                <Button
                  variant="contained"
                  startIcon={<EventAvailableOutlinedIcon />}
                  color="success"
                  onClick={() => {
                    handleChangeStatus(event.id, 'approved');
                  }}
                  className="w-[150px]"
                >
                  Approve
                </Button>
                <Button
                  variant="contained"
                  startIcon={<EventBusyOutlinedIcon />}
                  color="error"
                  onClick={() => {
                    handleChangeStatus(event.id, 'rejected');
                  }}
                  className="w-[150px]"
                >
                  Reject
                </Button>
                <Button
                  variant="contained"
                  startIcon={<PendingActionsOutlinedIcon />}
                  color="warning"
                  onClick={() => {
                    handleChangeStatus(event.id, 'pending');
                  }}
                  className="w-[150px]"
                >
                  Pending
                </Button>
                <Button
                  variant="contained"
                  startIcon={<BlockOutlinedIcon />}
                  color="secondary"
                  onClick={() => {
                    handleDelete(event.id);
                  }}
                  className="w-[150px]"
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        ))
      ) : (
        <div className="your-events-card">
          <h2>No Events</h2>
        </div>
      )}
    </div>
  );
}

export default EventCard;
