import { useContext, useEffect, useState } from 'react';
import { doc, getDoc, collection, where, query, getDocs } from 'firebase/firestore';
import { db } from '../../firebase.config';
import FirebaseContext from '../../context/FirebaseContext';
import './Schedule.css';
import ScheduleHeader from '../../components/ScheduleHeader/ScheduleHeader';
import Calendar from '../../components/calendar/Calendar';

function Schedule() {
  const { isAdmin, user, setLoading } = useContext(FirebaseContext);
  const [events, setEvents] = useState([]);

  useEffect(() => {
    const getUserData = async (holiday) => {
      const docRef = doc(db, 'users', holiday.userRef);
      const dataSnap = await getDoc(docRef);
      const data = dataSnap.data();
      return data;
    };

    const getEvents = async () => {
      setLoading(true);
      if (isAdmin) {
        // Get all Events
        const collectionRef = collection(db, 'events');
        const querySnap = await getDocs(collectionRef);
        const data = querySnap.docs.map((doc) => doc.data());

        // Get user Info
        const events = [];
        for (const event of data) {
          const userData = await getUserData(event);
          events.push({
            ...event,
            userData,
          });
        }
        events.sort((a, b) => b.actualDays - a.actualDays);
        setEvents(events);
      } else {
        const collectionRef = collection(db, 'events');
        const q1 = where('userRef', '==', user.uid);
        const queryRef = query(collectionRef, q1);
        const dataSnap = await getDocs(queryRef);
        const data = dataSnap.docs.map((doc) => doc.data());

        // Get User Info
        const events = [];
        for (const event of data) {
          events.push({
            ...event,
            userData: user,
          });
        }

        events.sort((a, b) => b.actualDays - a.actualDays);
        setEvents(events);
      }
      setLoading(false);
    };

    if (user) {
      getEvents();
    }
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <section className="schedule-section px-10">
      <ScheduleHeader />

      <Calendar events={events} />
    </section>
  );
}

export default Schedule;
