import { useState, useEffect, useContext } from 'react';
import FirebaseContext from '../../context/FirebaseContext';
import {
  getFirestore,
  collection,
  where,
  orderBy,
  query,
  getDocs,
  doc,
  deleteDoc,
  updateDoc,
} from 'firebase/firestore';
import { toast } from 'react-toastify';
import ScheduleHeader from '../../components/ScheduleHeader/ScheduleHeader';
import './AllEvents.css';
import { emailChangeStatus } from '../../utils/emailController';

import EventCard from '../../components/EventCard/EventCard';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

function AllEvents() {
  const { setLoading, user } = useContext(FirebaseContext);
  const [events, setEvents] = useState([]);
  const [users, setUsers] = useState([]);
  const [sortingUser, setSortingUser] = useState('all');
  const [sortingMonth, setSortingMonth] = useState(new Date().getMonth());
  const [sortingYear, setSortingYear] = useState(new Date().getFullYear());
  const [sortingType, setSortingType] = useState('all');

  const years = [];
  for (let i = 2020; i <= new Date().getFullYear(); i++) {
    years.push(i);
  }
  years.reverse();

  useEffect(() => {
    const getAllUsers = async () => {
      try {
        const db = getFirestore();
        const collectionRef = collection(db, 'users');
        const querySnapshot = await getDocs(collectionRef);
        const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setUsers(data);
      } catch (error) {
        toast.error(error.message);
      }
    };

    getAllUsers();
  }, []);

  useEffect(() => {
    const getUserInfo = async (userRef) => {
      try {
        const db = getFirestore();
        const collectionRef = collection(db, 'users');
        const q = query(collectionRef, where('userRef', '==', userRef));
        const querySnapshot = await getDocs(q);
        const data = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        return data[0];
      } catch (error) {
        toast.error(error.message);
      }
    };

    const getAllEvents = async (dates) => {
      setLoading(true);
      try {
        const db = getFirestore();
        const collectionRef = collection(db, 'events');
        const order = orderBy('startDate', 'desc');
        let queryRef;
        const q4 = where('reason', '==', sortingType);
        if (dates?.start && dates?.end) {
          const q2 = where('startDate', '>=', dates.start);
          const q3 = where('startDate', '<=', dates.end);
          if (sortingType === 'all') {
            queryRef = query(collectionRef, q2, q3, order);
          } else {
            queryRef = query(collectionRef, q2, q3, q4, order);
          }
        } else {
          if (sortingType === 'all') {
            queryRef = query(collectionRef, order);
          } else {
            queryRef = query(collectionRef, q4, order);
          }
        }
        const querySnap = await getDocs(queryRef);
        const data = [];
        for (const event of querySnap.docs) {
          const userInfo = await getUserInfo(event.data().userRef);
          data.push({ ...event.data(), userInfo, id: event.id });
        }
        setEvents(data);
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
      setLoading(false);
    };

    const getUserEvents = async (dates) => {
      setLoading(true);
      try {
        const db = getFirestore();
        const collectionRef = collection(db, 'events');
        const q1 = where('userRef', '==', sortingUser);
        const order = orderBy('startDate', 'desc');
        let queryRef;
        const q4 = where('reason', '==', sortingType);
        if (dates?.start && dates?.end) {
          const q2 = where('startDate', '>=', dates.start);
          const q3 = where('startDate', '<=', dates.end);
          if (sortingType === 'all') {
            queryRef = query(collectionRef, q1, q2, q3, order);
          } else {
            queryRef = query(collectionRef, q1, q2, q3, q4, order);
          }
        } else {
          if (sortingType === 'all') {
            queryRef = query(collectionRef, q1, order);
          } else {
            queryRef = query(collectionRef, q1, q4, order);
          }
        }
        const querySnap = await getDocs(queryRef);
        const data = [];
        for (const event of querySnap.docs) {
          const userInfo = users.find((user) => user.userRef === event.data().userRef);
          data.push({ ...event.data(), userInfo, id: event.id });
        }
        setEvents(data);
      } catch (error) {
        console.log(error);
        toast.error(error.message);
      }
      setLoading(false);
    };

    const constructDate = (date) => {
      const dates = {};
      if (sortingMonth === 'all' && sortingYear === 'all') {
        dates.start = null;
        dates.end = null;
      } else if (sortingMonth !== 'all' && sortingYear !== 'all') {
        const month = Number(sortingMonth) + 1 < 10 ? `0${Number(sortingMonth) + 1}` : Number(sortingMonth) + 1;
        const year = sortingYear;
        dates.start = new Date(`${year}-${month}-01`);
        dates.end = new Date(`${year}-${month}-31`);
      } else if (sortingMonth === 'all' && sortingYear !== 'all') {
        const year = sortingYear;
        dates.start = new Date(`${year}-01-01`);
        dates.end = new Date(`${year}-12-31`);
      }
      return dates;
    };

    const dates = constructDate();
    if (sortingUser !== 'all') {
      getUserEvents(dates);
    } else {
      getAllEvents(dates);
    }
  }, [sortingUser, sortingMonth, sortingYear, sortingType]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleChangeStatus = async (id, status) => {
    setLoading(true);
    try {
      const db = getFirestore();
      const docRef = doc(db, 'events', id);
      await updateDoc(docRef, { status: status });
      const newEvents = events.map((event) => {
        if (event.id === id) {
          event.status = status;
        }
        return event;
      });
      setEvents(newEvents);

      // Send email to user
      const currentEvent = events.find((event) => event.id === id);
      await emailChangeStatus(currentEvent, user);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleDelete = async (id) => {
    setLoading(true);
    try {
      const db = getFirestore();
      const docRef = doc(db, 'events', id);
      await deleteDoc(docRef);

      // Send email to user
      const currentEvent = events.find((event) => event.id === id);
      currentEvent.status = 'cancelled';
      await emailChangeStatus(currentEvent, user);

      const newEvents = events.filter((event) => event.id !== id);
      setEvents(newEvents);
      toast.success('Event has been deleted!');
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  return (
    <section className="all-events-section  px-10">
      <ScheduleHeader />
      <h1>
        <BallotOutlinedIcon fontSize="large" /> All Events
      </h1>
      <div className="sorting-container flex justify-center items-center gap-10 flex-wrap w-full">
        <FormControl variant="standard" className="max-w-[300px] w-full tablet:max-w-full">
          <InputLabel id="user-selection-label">User</InputLabel>
          <Select
            labelId="user-selection-label"
            id="user-selection"
            label="user-selection"
            value={sortingUser}
            onChange={(e) => {
              setSortingUser(e.target.value);
            }}
          >
            <MenuItem key={'all-key'} value="all">
              <em>All</em>
            </MenuItem>
            {users.length > 0 &&
              users.map((user) => (
                <MenuItem key={user.userRef} value={user.userRef}>
                  {user.firstName + ' ' + user.lastName}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
        {/* TODO: Type Sorting */}
        <FormControl variant="standard" className="max-w-[200px] w-full tablet:max-w-full">
          <InputLabel id="type-selection-label">Type</InputLabel>
          <Select
            labelId="type-selection-label"
            id="type-selection"
            label="type-selection"
            value={sortingType}
            onChange={(e) => {
              setSortingType(e.target.value);
            }}
          >
            <MenuItem key={'all-type-key'} value="all">
              <em>All</em>
            </MenuItem>
            <MenuItem key={'vacation-key'} value={'vacation'}>
              Vacation
            </MenuItem>
            <MenuItem key={'dayoff-key'} value={'dayoff'}>
              Dayoff
            </MenuItem>
            <MenuItem key={'sick-key'} value={'sick'}>
              Sick
            </MenuItem>
            <MenuItem key={'other-key'} value={'other'}>
              Other
            </MenuItem>
          </Select>
        </FormControl>
        {/* Month Sorting */}
        <FormControl variant="standard" className="max-w-[200px] w-full tablet:max-w-full">
          <InputLabel id="month-selection-label">Month</InputLabel>
          <Select
            labelId="month-selection-label"
            id="month-selection"
            label="month-selection"
            value={sortingMonth}
            onChange={(e) => {
              sortingYear === 'all' ? setSortingMonth('all') : setSortingMonth(e.target.value);
            }}
          >
            <MenuItem key={'all-key'} value="all">
              <em>All</em>
            </MenuItem>
            <MenuItem key={'january-key'} value="0">
              January
            </MenuItem>
            <MenuItem key={'february-key'} value="1">
              February
            </MenuItem>
            <MenuItem key={'march-key'} value="2">
              March
            </MenuItem>
            <MenuItem key={'april-key'} value="3">
              April
            </MenuItem>
            <MenuItem key={'may-key'} value="4">
              May
            </MenuItem>
            <MenuItem key={'june-key'} value="5">
              June
            </MenuItem>
            <MenuItem key={'july-key'} value="6">
              July
            </MenuItem>
            <MenuItem key={'august-key'} value="7">
              August
            </MenuItem>
            <MenuItem key={'september-key'} value="8">
              September
            </MenuItem>
            <MenuItem key={'october-key'} value="9">
              October
            </MenuItem>
            <MenuItem key={'november-key'} value="10">
              November
            </MenuItem>
            <MenuItem key={'december-key'} value="11">
              December
            </MenuItem>
          </Select>
        </FormControl>
        {/* TODO: Year Sorting */}
        <FormControl variant="standard" className="max-w-[200px] w-full tablet:max-w-full">
          <InputLabel id="year-selection-label">Year</InputLabel>
          <Select
            labelId="year-selection-label"
            id="year-selection"
            label="year-selection"
            value={sortingYear}
            onChange={(e) => {
              e.target.value === 'all' && setSortingMonth('all');
              setSortingYear(e.target.value);
            }}
          >
            <MenuItem key={'all-key'} value="all">
              <em>All</em>
            </MenuItem>
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <EventCard
        events={events}
        handleDelete={handleDelete}
        adminControls={true}
        handleChangeStatus={handleChangeStatus}
      />
    </section>
  );
}

export default AllEvents;
