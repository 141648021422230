import { format } from 'date-fns';

/** Formats a date to a string with a fixed format.
 * @param {Date} date - The date to be formatted. If the value is not a date, it will be returned as is.
 * @param {String} setFormat - The format to be displayed. Default is 'd LLL yyyy'.
 * @return {String} - The formatted date.
 */
const formatDate = (date, setFormat = 'd LLL yyyy') => {
  // Check if date is valid
  if (date instanceof Date && !isNaN(date)) {
    return format(date, setFormat);
  } else {
    return date;
  }
};

export default formatDate;
