import { useEffect } from 'react';
import { NavLink, Routes, Route, useNavigate } from 'react-router-dom';
import CreateUser from './CreateUser';
import Vacations from './Vacations';
import './AdminPanel.css';

function AdminPanel() {
  const navigate = useNavigate();

  useEffect(() => {
    const location = window.location.pathname;
    if (location === '/admin-panel') {
      navigate('/admin-panel/vacations');
    }
  }, [navigate]);

  return (
    <section className="admin-panel w-full px-5">
      <nav className="tabs">
        <NavLink
          to="/admin-panel/create-user"
          className={({ isActive }) => {
            return isActive ? 'tab tab-bordered tab-active' : 'tab tab-bordered';
          }}
        >
          New User
        </NavLink>
        <NavLink
          to="/admin-panel/vacations"
          className={({ isActive }) => {
            return isActive ? 'tab tab-bordered tab-active' : 'tab tab-bordered';
          }}
        >
          Vacations
        </NavLink>
      </nav>
      <section className="w-full overflow-auto">
        <Routes>
          <Route path="/create-user" element={<CreateUser />} />
          <Route path="/vacations" element={<Vacations />} />
        </Routes>
      </section>
    </section>
  );
}

export default AdminPanel;
