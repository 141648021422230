import { createContext, useState, useRef, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

import { db } from '../firebase.config';
import { doc, getDoc } from 'firebase/firestore';

import Spinner from '../components/layout/Spinner';
import { toast } from 'react-toastify';

const FirebaseContext = createContext();

export const FirebaseProvider = ({ children }) => {
  const [loggedIn, setLoggedIn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(user?.accountType === 'admin' ? true : false);
  const [updateUser, setUpdateUser] = useState(false);

  const isMounted = useRef(true);

  // Check if user is logged in
  useEffect(() => {
    if (isMounted) {
      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        setLoading(true);
        if (user) {
          const docRef = doc(db, 'users', user.uid);
          const dataSnap = await getDoc(docRef);
          const data = dataSnap.data();
          const newUser = {
            ...data,
            uid: user.uid,
          };

          setUser(newUser);
          setLoggedIn(true);
          setIsAdmin(newUser?.accountType === 'admin' ? true : false);
          newUser?.accountType === 'admin'
            ? toast.info('Admin privileges granted!')
            : toast.success('Login successful!');
        } else {
          setUser(null);
          setLoggedIn(false);
          setIsAdmin(false);
        }
        setLoading(false);
      });
    }
    updateUser && setUpdateUser(false);

    return () => {
      isMounted.current = false;
    };
  }, [isMounted, updateUser]);

  return (
    <FirebaseContext.Provider
      value={{
        user,
        loading,
        loggedIn,
        isAdmin,
        setLoading,
        setUpdateUser,
      }}
    >
      {children}
      {loading && <Spinner />}
    </FirebaseContext.Provider>
  );
};

export default FirebaseContext;
