import { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, addDoc, getDocs, where, query } from 'firebase/firestore';

import FirebaseContext from '../../context/FirebaseContext';
import { toast } from 'react-toastify';
import ScheduleHeader from '../../components/ScheduleHeader/ScheduleHeader';
import './AddNewEvent.css';

import { MdOutlineEventAvailable } from 'react-icons/md';
import Button from '@mui/material/Button';
import SendIcon from '@mui/icons-material/Send';
import { emailNewEvent } from '../../utils/emailController';

function AddNewEvent() {
  const navigate = useNavigate();
  const { user, setLoading, isAdmin } = useContext(FirebaseContext);

  const startDate = new Date();
  startDate.setHours(12, 0, 0, 0);
  const endDate = new Date();
  endDate.setHours(13, 0, 0, 0);
  const [newEvent, setNewEvent] = useState({
    startDate: startDate,
    endDate: endDate,
    requestedDays: 1,
    actualDays: 1,
    reason: 'vacation',
    color: user.color,
    userRef: user.uid,
    status: 'pending',
  });

  const [requestors, setRequestors] = useState([user]);
  const [currentRequestor, setCurrentRequestor] = useState(user);

  useEffect(() => {
    const getRequestors = async () => {
      setLoading(true);
      try {
        const db = getFirestore();
        if (isAdmin) {
          const collectionRef = collection(db, 'users');
          const querySnap = await getDocs(collectionRef);
          const users = querySnap.docs.map((doc) => doc.data());
          setRequestors(users);
          setCurrentRequestor(user);
        } else {
          setRequestors([user]);
          setCurrentRequestor(user);
        }
      } catch (error) {
        toast.error(error.message);
      }
      setLoading(false);
    };

    getRequestors();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const getEventsForRequestor = async () => {
      setLoading(true);
      try {
        const db = getFirestore();
        const collectionRef = collection(db, 'events');

        const beginDate = new Date();
        beginDate.setFullYear(new Date().getFullYear(), 0, 1);
        const endDate = new Date();
        endDate.setFullYear(new Date().getFullYear(), 11, 31);
        const q1 = where('userRef', '==', currentRequestor.userRef);
        const q2 = where('status', '==', 'approved');
        const q3 = where('reason', '==', 'vacation');
        const q4 = where('startDate', '>=', beginDate);
        const q5 = where('startDate', '<=', endDate);
        const queryRef = query(collectionRef, q1, q2, q3, q4, q5);
        const querySnap = await getDocs(queryRef);
        const events = querySnap.docs.map((doc) => doc.data());
        const totalVacations = events.reduce((acc, event) => {
          return acc + event.requestedDays;
        }, 0);
        const remainingVacations = currentRequestor.vacationDays + currentRequestor.vacationCorrection - totalVacations;
        currentRequestor.totalVacations = totalVacations;
        currentRequestor.remainingVacations = remainingVacations;
      } catch (error) {
        toast.error(error.message);
      }
      setLoading(false);
    };

    getEventsForRequestor();
  }, [currentRequestor]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const db = getFirestore();
      const collectionRef = collection(db, 'events');

      const startDate = new Date(newEvent.startDate);
      startDate.setHours(0, 0, 0, 0);
      const endDate = new Date(newEvent.endDate);
      endDate.setHours(23, 59, 59, 999);

      await addDoc(collectionRef, {
        ...newEvent,
        startDate: startDate,
        endDate: endDate,
        createdAt: new Date(),
      });

      // Send email
      const currentEvent = {
        reason: newEvent.reason,
        firstName: currentRequestor.firstName,
        lastName: currentRequestor.lastName,
        startDate: startDate,
        endDate: endDate,
        requestedDays: newEvent.requestedDays,
        actualDays: newEvent.actualDays,
      };
      await emailNewEvent(currentEvent);

      toast.success('Event has been placed!');
      navigate('/schedule');
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  const handleSelection = (e) => {
    const userRef = e.target.value;
    const selectedUser = requestors.find((user) => user.userRef === userRef);
    setCurrentRequestor(selectedUser);
    setNewEvent({ ...newEvent, userRef: selectedUser.userRef, color: selectedUser.color });
  };

  return (
    <section className="add-new-event-section px-10">
      <ScheduleHeader />
      <h1>
        <MdOutlineEventAvailable /> New Event
      </h1>
      <form className="holiday-form shadow-lg rounded-lg" onSubmit={handleSubmit}>
        <div className="container">
          <label htmlFor="users">User</label>
          <select
            name="users"
            id="users"
            className="input input-sm input-bordered input-primary w-full"
            value={currentRequestor.userRef}
            onChange={handleSelection}
          >
            {requestors.length > 0 &&
              requestors.map((requestor) => {
                return (
                  <option key={requestor.userRef} value={requestor.userRef}>
                    {requestor.firstName + ' ' + requestor.lastName}
                  </option>
                );
              })}
          </select>
        </div>
        <div className="container">
          <label htmlFor="start-date">Start Date</label>
          <input
            className="input input-sm input-bordered input-primary w-full"
            type="date"
            id="start-date"
            name="startDate"
            value={new Date(newEvent.startDate).toISOString().split('T')[0]}
            onChange={(e) => {
              const newDate = new Date(e.target.value);
              const endDate = newDate > newEvent.endDate ? newDate : newEvent.endDate;
              const actualDays = newDate > endDate ? 0 : Math.ceil((endDate - newDate) / (1000 * 60 * 60 * 24)) + 1;
              const requestedDays = newEvent.requestedDays > actualDays ? actualDays : newEvent.requestedDays;
              setNewEvent({
                ...newEvent,
                startDate: newDate,
                endDate: endDate,
                actualDays: Number(actualDays),
                requestedDays: Number(requestedDays),
              });
            }}
          />
        </div>
        <div className="container">
          <label htmlFor="end-date">End Date</label>
          <input
            className="input input-sm input-bordered input-primary w-full"
            type="date"
            id="end-date"
            name="endDate"
            value={new Date(newEvent.endDate).toISOString().split('T')[0]}
            onChange={(e) => {
              const newDate = new Date(e.target.value);
              const endDate = newDate < newEvent.startDate ? newEvent.startDate : newDate;
              const actualDays =
                endDate < newEvent.startDate
                  ? 0
                  : Math.ceil((endDate - newEvent.startDate) / (1000 * 60 * 60 * 24)) + 1;
              const requestedDays = newEvent.requestedDays > actualDays ? actualDays : newEvent.requestedDays;
              setNewEvent({
                ...newEvent,
                endDate: endDate,
                actualDays: Number(actualDays),
                requestedDays: Number(requestedDays),
              });
            }}
          />
        </div>
        <div className="container">
          <label htmlFor="working-days">Requested Days</label>
          <input
            className="input input-sm input-bordered input-primary w-full"
            type="number"
            id="requested-days"
            name="requestedDays"
            step={1}
            min={0}
            placeholder="Enter number of working days"
            value={newEvent.requestedDays}
            onChange={(e) => {
              setNewEvent({
                ...newEvent,
                requestedDays: Number(e.target.value > newEvent.actualDays ? newEvent.actualDays : e.target.value),
              });
            }}
          />
        </div>
        <div className="container">
          <label htmlFor="total-days">Actual Days</label>
          <input
            className="input input-sm input-bordered w-full"
            type="number"
            id="actual-days"
            name="actualDays"
            value={newEvent.actualDays}
            readOnly
          />
        </div>
        <div className="container">
          <label htmlFor="year-days">Available Days / Year</label>
          <input
            className="input input-sm input-bordered w-full"
            type="number"
            id="year-days"
            name="yearDays"
            value={currentRequestor.vacationDays}
            readOnly
          />
        </div>
        <div className="container">
          <label htmlFor="remaining-days">Remaining Days</label>
          <input
            className="input input-sm input-bordered w-full"
            type="number"
            id="remaining-days"
            name="remainingDays"
            step={1}
            value={currentRequestor.remainingVacations}
            readOnly
          />
        </div>
        <div className="container">
          <label htmlFor="remaining-days">Reason</label>
          <select
            name="reason"
            id="reason"
            className="input input-sm input-bordered input-primary w-full"
            defaultValue={newEvent.reason}
            onChange={(e) => {
              setNewEvent({
                ...newEvent,
                reason: e.target.value,
                dayoffStartDate: e.target.value === 'dayoff' ? new Date(newEvent.startDate) : null,
                dayoffEndDate: e.target.value === 'dayoff' ? new Date(newEvent.startDate) : null,
              });
            }}
          >
            <option value="vacation" name="vacation">
              Vacation
            </option>
            <option value="sick" name="sick">
              Sick
            </option>
            <option value="dayoff" name="dayoff">
              Day Off
            </option>
            <option value="other" name="other">
              Other
            </option>
          </select>
        </div>
        {newEvent.reason === 'dayoff' && (
          <>
            <div className="container">
              <label htmlFor="dayoff-start-date">Day off for Job Started</label>
              <input
                className="input input-sm input-bordered input-primary w-full"
                type="date"
                id="dayoff-start-date"
                name="dayoffStartDate"
                value={new Date(newEvent.dayoffStartDate).toISOString().split('T')[0]}
                onChange={(e) => {
                  const startDate = new Date(e.target.value);
                  const endDate = startDate > newEvent.dayoffEndDate ? startDate : newEvent.dayoffEndDate;
                  setNewEvent({
                    ...newEvent,
                    dayoffStartDate: startDate,
                    dayoffEndDate: endDate,
                  });
                }}
              />
            </div>
            <div className="container">
              <label htmlFor="dayoff-end-date">Day off for Job Ended</label>
              <input
                className="input input-sm input-bordered input-primary w-full"
                type="date"
                id="dayoff-end-date"
                name="dayoffEndDate"
                value={new Date(newEvent.dayoffEndDate).toISOString().split('T')[0]}
                onChange={(e) => {
                  const newDate = new Date(e.target.value);
                  const endDate = newDate < newEvent.dayoffStartDate ? newEvent.dayoffStartDate : newDate;
                  setNewEvent({
                    ...newEvent,
                    dayoffEndDate: endDate,
                  });
                }}
              />
            </div>
          </>
        )}
        {/* <input type="submit" className="btn btn-sm btn-outline btn-secondary" value="Submit Event" /> */}
        <Button type="submit" variant="contained" endIcon={<SendIcon />}>
          Submit
        </Button>
      </form>
    </section>
  );
}

export default AddNewEvent;
