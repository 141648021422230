import { useState, useContext } from 'react';
import { toast } from 'react-toastify';
import { getAuth, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { secondaryUser } from '../../firebase.config';
import FirebaseContext from '../../context/FirebaseContext';
import { emailNewUser } from '../../utils/emailController';

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from '@mui/material/Button';
import { MuiColorInput } from 'mui-color-input';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import ColorLensOutlinedIcon from '@mui/icons-material/ColorLensOutlined';

function CreateUser() {
  const { setLoading } = useContext(FirebaseContext);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  const [color, setColor] = useState('#' + Math.floor(Math.random() * 16777215).toString(16));

  const handleSubmit = async (e) => {
    if (firstName === '' || lastName === '' || email === '' || password === '') {
      toast.error('All fields are required');
      return;
    }
    setLoading(true);
    try {
      // Create User in Firebase Auth
      const auth = getAuth(secondaryUser);
      const userCredentials = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredentials.user;
      await updateProfile(auth.currentUser, {
        displayName: `${firstName} ${lastName}`,
      });

      // Create User in Database
      const userToStore = {
        firstName,
        lastName,
        email,
        accountType: isAdmin ? 'admin' : 'user',
        color,
        userRef: user.uid,
        vacationDays: 22,
        vacationCorrection: 0,
      };

      const db = getFirestore();
      const docRef = doc(db, 'users', user.uid);
      await setDoc(docRef, userToStore);
      toast.success('User Created');

      // Send Email to User
      await emailNewUser(email, password);

      // clear states
      setFirstName('');
      setLastName('');
      setEmail('');
      setPassword('');
      setIsAdmin(false);
    } catch (error) {
      toast.error(error.message);
    }
    setLoading(false);
  };

  return (
    <section className="new-user flex flex-col justify-start">
      <h1 className="text-lg font-semibold">New User</h1>
      <Box style={{ display: 'flex', alignItems: 'flex-end', marginTop: 20, width: '100%' }}>
        <AccountCircleOutlinedIcon sx={{ color: 'action.active', mr: 2, my: 0.5 }} />
        <TextField
          id="user-first-name"
          type="text"
          label="First Name"
          autoComplete="given-name"
          variant="standard"
          style={{ width: '100%', maxWidth: 400 }}
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
      </Box>
      <Box style={{ display: 'flex', alignItems: 'flex-end', marginTop: 20, width: '100%' }}>
        <AccountCircleOutlinedIcon sx={{ color: 'action.active', mr: 2, my: 0.5 }} />
        <TextField
          id="user-last-name"
          type="text"
          label="Last Name"
          autoComplete="family-name"
          variant="standard"
          style={{ width: 400 }}
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Box>
      <Box style={{ display: 'flex', alignItems: 'flex-end', marginTop: 20, width: '100%' }}>
        <EmailOutlinedIcon sx={{ color: 'action.active', mr: 2, my: 0.5 }} />
        <TextField
          id="user-email"
          type="email"
          label="Email"
          autoComplete="email"
          variant="standard"
          style={{ width: 400 }}
          value={email}
          onChange={(e) => {
            const emailParts = e.target.value.split('@');
            const email = emailParts[0];
            const emailWithoutSpecialCharacters = email.replace(/[^a-zA-Z0-9]/g, '');
            const newPassword = emailWithoutSpecialCharacters + new Date().getFullYear();
            setEmail(e.target.value);
            setPassword(newPassword);
          }}
        />
      </Box>
      <Box style={{ display: 'flex', alignItems: 'flex-end', marginTop: 20, width: '100%' }}>
        <LockOutlinedIcon sx={{ color: 'action.active', mr: 2, my: 0.5 }} />
        <TextField
          id="user-password"
          type="password"
          label="Password"
          variant="standard"
          style={{ width: 400 }}
          disabled
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Box>
      <Box style={{ display: 'flex', alignItems: 'center', marginTop: 30, maxWidth: '100%' }}>
        <ColorLensOutlinedIcon sx={{ color: 'action.active', mr: 2, my: 0.5 }} />
        <MuiColorInput
          value={color}
          onChange={(color1) => {
            // RGB string
            const rgb = color1;
            // Convert to hex
            const hex = rgb
              .replace(/^rgb\(|\s+|\)$/g, '')
              .split(',')
              .map((x) => {
                const hex = Number(x).toString(16);
                return hex.length === 1 ? '0' + hex : hex;
              })
              .join('');
            setColor(`#${hex}`);
          }}
          style={{ width: 400 }}
        />
      </Box>
      <FormGroup style={{ display: 'flex', alignItems: 'flex-start', marginTop: 20, marginLeft: 5, maxWidth: '100%' }}>
        <FormControlLabel
          control={<Checkbox value={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)} />}
          label="Is Administrator?"
          style={{ width: '100%' }}
        />
      </FormGroup>
      <Button variant="contained" style={{ marginTop: 20, width: '100%', maxWidth: 500 }} onClick={handleSubmit}>
        Create New User
      </Button>
    </section>
  );
}

export default CreateUser;
