import { useContext } from 'react';
import { Link } from 'react-router-dom';
import FirebaseContext from '../../context/FirebaseContext';
import './ScheduleHeader.css';

import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import EventAvailableOutlinedIcon from '@mui/icons-material/EventAvailableOutlined';
import LocalActivityOutlinedIcon from '@mui/icons-material/LocalActivityOutlined';
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined';
import BallotOutlinedIcon from '@mui/icons-material/BallotOutlined';

function ScheduleHeader() {
  const { isAdmin } = useContext(FirebaseContext);

  return (
    <header className="schedule-header">
      <div className="flex justify-center items-center flex-wrap gap-5 ">
        <Button
          component={Link}
          to={'/schedule'}
          variant="contained"
          startIcon={<CalendarMonthOutlinedIcon />}
          className="w-[200px]"
        >
          Schedule
        </Button>
        <Button
          component={Link}
          to={'/schedule/add-new-event'}
          variant="contained"
          startIcon={<EventAvailableOutlinedIcon />}
          className="w-[200px]"
        >
          New Event
        </Button>
        <Button
          component={Link}
          to={'/schedule/your-events'}
          variant="contained"
          startIcon={<LocalActivityOutlinedIcon />}
          className="w-[200px]"
        >
          Your Events
        </Button>
        {isAdmin && (
          <>
            <Button
              component={Link}
              to={'/schedule/pending-events'}
              variant="contained"
              startIcon={<PendingActionsOutlinedIcon />}
              color="warning"
              className="w-[200px]"
            >
              Pending Events
            </Button>
            <Button
              component={Link}
              to={'/schedule/all-events'}
              variant="contained"
              startIcon={<BallotOutlinedIcon />}
              color="warning"
              className="w-[200px]"
            >
              All Events
            </Button>
          </>
        )}
      </div>
    </header>
  );
}

export default ScheduleHeader;
