import { Button } from '@mui/material';
import { useRef } from 'react';

import EmailEditor from 'react-email-editor';

function exportToHTML(text) {
  const blob = new Blob([text], { type: 'text/html' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.download = 'email-design.html';
  link.href = url;
  link.click();
}

function Editor() {
  const emailEditorRef = useRef(null);

  const exportHtml = () => {
    if (!emailEditorRef.current) return;

    emailEditorRef.current.editor.exportHtml((data) => {
      const { design, html } = data;
      exportToHTML(html);
    });
  };

  const onLoad = () => {
    // editor instance is created
    // you can load your template here;
    // const templateJson = {};
    // emailEditorRef.current.editor.loadDesign(templateJson);
  };

  const onReady = () => {
    // editor is ready
  };

  return (
    <section className="w-full min-h-screen flex flex-col justify-center items-center gap-5">
      <div className="w-full flex justify-center items-center gap-5 p-2">
        {/* <Button variant="contained" color="secondary">
          Load Design
        </Button>
        <Button variant="contained" color="secondary">
          Save Design
        </Button> */}
        <Button variant="contained" color="secondary" onClick={exportHtml}>
          Export HTML
        </Button>
      </div>
      <div className="flex-1 w-full h-full flex">
        <EmailEditor ref={emailEditorRef} onLoad={onLoad} onReady={onReady} />
      </div>
    </section>
  );
}

export default Editor;
